<template>
  <UAlert
    color="rose"
    variant="soft"
    :class="$props.class"
    data-cy="cy-error-alert"
  >
    <template #title>
      <UiErrorValidation v-if="validationError" :errors="validationError" />
      <div v-else>
        {{ messageError }}
      </div>
    </template>
  </UAlert>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import type { CombinedError } from '@urql/core';

const props = defineProps<{
  error: CombinedError,
  class?: HTMLAttributes['class'],
}>();

const errors = computed(() => {
  const rawErrors = toRaw(props.error);
  return rawErrors?.graphQLErrors && rawErrors.graphQLErrors.length > 0
    ? rawErrors.graphQLErrors[0]
    : undefined;
});

const messageError = computed(() => ((errors.value?.extensions && 'debugMessage' in errors.value.extensions)
  ? `${errors.value.message} (${errors.value.extensions.debugMessage})`
  : errors.value?.message
));
const validationError = computed(
  () => errors.value?.extensions?.validation as Record<string, string[]>,
);

</script>
